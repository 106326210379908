<template>
  <span class="edit-date-comments-modal" v-if="canEdit">
    <b-modal
      dialog-class="modal-md"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      :ok-title="editComment ? 'Enregistrer' : 'Ajouter'"
      :ok-disabled="!isValid()"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fas fa-comment"></i>&nbsp;
          <span v-if="editComment">Modifier un commentaire</span>
          <span v-else>Ajout d'un commentaire</span>
        </b>
      </template>
      <div v-if="error" class="errors-message">
        {{ error }}
      </div>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Date"
            label-for="date"
          >
            <b-form-input id="date" type="date" v-model="date"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label-for="comments"
            label="Commentaires"
            description="max. 100 caractères"
          >
            <b-form-input type="text" v-model="comments" id="comments" maxlength="100">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeDateComment } from '@/types/agenda'

export default {
  name: 'edit-date-comments-modal',
  components: { },
  mixins: [BackendMixin],
  props: {
    modalId: String,
    editComment: Object,
    day: String,
  },
  data() {
    return {
      date: null,
      error: '',
      comments: '',
    }
  },
  computed: {
    canEdit() {
      if (this.editComment) {
        return this.hasPerm('agenda.change_datecomment')
      } else {
        return this.hasPerm('agenda.add_datecomment')
      }
    },
    canDelete() {
      return this.hasPerm('agenda.delete_datecomment')
    },
  },
  watch: {
    editEvent: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async init() {
      this.date = this.day
      if (this.editComment) {
        this.comments = this.editComment.comments
      } else {
        this.comments = ''
      }
    },
    async onSave() {
      this.error = ''
      let url = '/agenda/api/date-comments/'
      if (this.editComment) {
        url += this.editComment.id + '/'
      }
      let method = this.editComment ? 'patch' : 'post'
      let data = {
        date: this.date,
        comments: this.comments.substring(0, 100),
      }
      if ((method === 'patch') && (this.comments === '')) {
        method = 'delete'
        data = {}
      }
      let backendApi = new BackendApi(method, url)
      try {
        const resp = await backendApi.callApi(data)
        if (this.editComment && this.comments) {
          await this.addSuccess('Le commentaire a été modifié')
          this.$emit('updated', makeDateComment(resp.data))
        } else if (this.editComment && !this.comments) {
          await this.addSuccess('Le commentaire a été supprimé')
          this.$emit('deleted', this.editComment)
        } else {
          await this.addSuccess('Le commentaire a été ajouté')
          this.$emit('added', makeDateComment(resp.data))
        }
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        this.error = this.getErrorText(err)
      }
    },
    isValid() {
      if (!this.date) {
        return false
      }
      if (this.canDelete) {
        if (!this.editComment && !this.comments) {
          return false
        }
      } else {
        if (!this.comments) {
          return false
        }
      }
      return true
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.errors-message {
  background: #f0ad4e;
  padding: 4px 10px;
  font-weight: bold;
}
</style>
