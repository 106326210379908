<template>
  <div class="daily-agenda" v-if="canView" id="cal">
    <page-header
      title="Agenda du jour"
      icon="fa fa-calendar-day"
      :links="getLinks()"
    >
    </page-header>
    <div ref="printMe">
      <b-row>
        <b-col cols="8">
          <b>Agenda du {{ dateToString(theDateAsString, 'dddd LL') }}</b>
        </b-col>
        <b-col cols="4">
          <date-selector id="theDateAsString" v-model="theDateAsString"></date-selector>
        </b-col>
      </b-row>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="!isLoading(loadingName)">
        <div>
          <div v-if="events.length === 0" class="empty-text">
            Aucune date d'agenda pour ce jour
          </div>
          <table class="table table-borderless small">
            <tr v-if="dateComments.length">
              <td colspan="4">
                <div v-html="dayComments()"></div>
              </td>
            </tr>
            <tbody v-for="place of places" :key="place.id">
              <tr>
                <th colspan="4">{{ place.name}}</th>
              </tr>
              <tr v-for="event of placeEvents(place)" :key="event.id">
                <td style="width: 50px; text-align: center;">{{ event.startAt() }}</td>
                <td style="width: 50px; text-align: center;">{{ event.endAt() }}</td>
                <td>{{ event.getLabel() }}</td>
                <td class="small-text" style="width: 300px">
                  {{ event.comments }}
                </td>
              </tr>
            <tr>
              <td colspan="4" style="border-bottom: solid 1px #ccc;">&nbsp;</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <edit-date-comments-modal
      modal-id="bv-edit-day-comments-modal"
      :edit-comment="dayComment"
      :day="theDateAsString"
      @updated="onCommentUpdate($event)"
      @added="onCommentAdded($event)"
      @deleted="onCommentDeleted($event)"
    ></edit-date-comments-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import DateSelector from '@/components/Controls/DateSelector.vue'
import EditDateCommentsModal from '@/components/Agenda/EditCommentModal.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeActivityDate, makeDateComment } from '@/types/agenda'
import { BackendApi, openDocument } from '@/utils/http'
import { distinct } from '@/utils/arrays'
import { dateToString } from '@/filters/texts'
import { escapeHtml } from '@/utils/html'

export default {
  name: 'daily-agenda',
  props: {
  },
  mixins: [BackendMixin],
  components: {
    DateSelector,
    EditDateCommentsModal,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'dailyAgenda',
      placesDates: [],
      dateComments: [],
      theDateAsString: '',
      dayComment: null,
    }
  },
  async created() {
    if (this.$route.query && this.$route.query.date) {
      this.theDateAsString = this.$route.query.date
    } else {
      this.theDateAsString = moment().format('YYYY-MM-DD')
    }
    await this.loadData()
  },
  watch: {
    theDate: function(newValue) {
      let isValid = false
      if (newValue) {
        isValid = moment(newValue, 'YYYY-MM-DD').isValid()
      }
      if (isValid) {
        this.loadData()
      } else {
        this.theDateAsString = dateToString(moment().toDate(), 'YYYY-MM-DD')
      }
    },
  },
  computed: {
    places() {
      return distinct(this.events.map(event => event.place))
    },
    theDate() {
      return moment(this.theDateAsString, 'YYYY-MM-DD').toDate()
    },
    canView() {
      return this.hasPerm('agenda.view_activitydate')
    },
    canChange() {
      return this.hasPerm('agenda.add_activitydate')
    },
    events() {
      return this.placesDates.filter(elt => !elt.absence)
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    dateToString,
    async loadData() {
      if (this.canView) {
        this.startLoading(this.loadingName)
        await this.loadActivityDates()
        await this.loadDateComments()
        this.endLoading(this.loadingName)
      }
    },
    async loadActivityDates() {
      try {
        const today = moment(this.theDateAsString).format('YYYY-MM-DD')
        let url = '/agenda/api/day-dates/' + today + '/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.placesDates = resp.data.map(elt => makeActivityDate(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onCommentAdded(event) {
      this.dateComments = [event]
      this.dayComment = event
    },
    onCommentDeleted(event) {
      this.dateComments = []
      this.dayComment = null
    },
    onCommentUpdate(event) {
      this.dateComments = [event]
      this.dayComment = event
    },
    async loadDateComments() {
      try {
        const today = moment(this.theDateAsString).format('YYYY-MM-DD')
        let url = '/agenda/api/date-comments/?weekly=0&base_date=' + today
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.dateComments = resp.data.map(makeDateComment)
        if (this.dateComments.length) {
          this.dayComment = this.dateComments[0]
        } else {
          this.dayComment = null
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const links = [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      let isAllowed = true
      if (this.dateComments) {
        isAllowed = this.hasPerm('agenda.change_datecomment')
      } else {
        isAllowed = this.hasPerm('agenda.add_datecomment')
      }
      if (isAllowed) {
        links.push(
          {
            id: 2,
            label: 'Commentaire',
            callback: () => {
              this.$bvModal.show('bv-edit-day-comments-modal')
            },
            icon: 'fa fa-comment',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
      }
      return links
    },
    placeEvents(place) {
      return this.events.filter(event => event.place.id === place.id)
    },
    dayComments() {
      return this.dateComments.map(elt => escapeHtml(elt.comments)).join('<br />')
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'agenda-' + this.theDateAsString
      const that = this
      setTimeout(
        async function() {
          const docContent = that.$refs.printMe.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await that.addError(that.getErrorText(err))
          }
        },
        100
      )
    },
  },
}
</script>

<style scoped lang="less">
</style>
