<template>
  <div>
    <b-row>
      <b-col :cols="colSize(1)" class="text-right nav-date" :class="{ 'small-buttons': smallButtons }">
        <a class="btn btn-sm btn-secondary" href @click.prevent="setLastWeek()" title="Semaine précédente">
          <i class="fa fa-angle-double-left"></i>
        </a>
        <a v-if="!weekly" class="btn btn-sm btn-secondary" href @click.prevent="setYesterday()" title="Hier">
          <i class="fa fa-chevron-left"></i>
        </a>
        <a class="btn btn-sm btn-secondary" href @click.prevent="setToday()" title="Aujourd'hui">
          <i class="fa fa-calendar-o"></i>
        </a>
        <a v-if="!weekly" class="btn btn-sm btn-secondary" href @click.prevent="setTomorrow()" title="Demain">
          <i class="fa fa-chevron-right"></i>
        </a>
        <a class="btn btn-sm btn-secondary" href @click.prevent="setNewtWeek()" title="Semaine suivante">
          <i class="fa fa-angle-double-right"></i>
        </a>
      </b-col>
      <b-col :cols="colSize(2)" class="text-right">
        <b-input
          :class="{ 'small-input': small }"
          type="date" v-model="dateAsString" :id="id" :required="required" :disabled="disabled"
        >
        </b-input>
      </b-col>
    </b-row>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import moment from 'moment/moment'
import { dateToString } from '@/filters/texts'

export default {
  name: 'DateSelector',
  prop: ['value'],
  props: {
    id: String,
    value: String,
    required: {
      type: Boolean,
      defaultValue: false,
    },
    disabled: {
      type: Boolean,
      defaultValue: false,
    },
    block: {
      type: Boolean,
      defaultValue: false,
    },
    weekly: {
      type: Boolean,
      defaultValue: false,
    },
    small: {
      type: Boolean,
      defaultValue: false,
    },
    smallButtons: {
      type: Boolean,
      defaultValue: false,
    },
  },
  computed: {
  },
  watch: {
    value: function() {
      this.init()
    },
    dateAsString: function() {
      this.$emit('input', this.dateAsString)
    },
  },
  data() {
    return {
      dateAsString: '',
    }
  },
  methods: {
    colSize(col) {
      if (this.block) {
        return 12
      } else if (this.weekly && this.smallButtons) {
        return (col === 1) ? 4 : 8
      } else {
        return 6
      }
    },
    init() {
      this.dateAsString = this.value
    },
    setToday() {
      this.dateAsString = dateToString(moment().toDate(), 'YYYY-MM-DD')
    },
    setYesterday() {
      const date = moment(this.dateAsString).add(-1, 'days')
      this.dateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
    setTomorrow() {
      const date = moment(this.dateAsString).add(1, 'days')
      this.dateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
    setLastWeek() {
      const date = moment(this.dateAsString).add(-7, 'days')
      this.dateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
    setNewtWeek() {
      const date = moment(this.dateAsString).add(7, 'days')
      this.dateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style scoped lang="less">
.nav-date a {
  margin: 5px 2px;
}
.small-buttons a {
  font-size: 11px !important;
  margin: 5px 1px;
}
</style>
